import {MaterialTableProps} from '@material-table/core';
import DBTable from 'components/DBTable';
import NotificationStore from 'store/notification';
import useSWR from 'swr';
import React from 'react';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import {
	callSharedApi,
	DBTableQuery,
	getSharedProjectId,
	paginateParams,
	useSharedGithubAccountId,
	useSharedProjectId,
} from 'utils/network';
import {useLoadingIndicator} from 'utils/network/useLoadingIndicator';
import {CircularProgress, Stack} from '@mui/material';
import {renderMalwareColumnInListPage} from 'pages/DrCloud/common/renderMalwareColumn';
import {sortBySeverityLevel} from 'pages/DrCloud/ContainerScanDetails/customTableSorting';
import {renderCveColumn} from 'pages/DrCloud/common/renderCveColumn';
import {
	ProjectAssetType,
	ProjectAssetsRes,
} from 'pages/DrCloud/Projects/projectsDef';
import _ from 'lodash';
import GitHubRepoAssetSettings from 'pages/DrCloud/Projects/components/GitHubRepoAssetSettings';
import {METERING_CONTROL_MSG} from 'types/meterControlType';
import {AxiosResponse} from 'axios';
import {SBOMStreamDef} from 'pages/Registry/StreamSourceTable';
import {Link} from 'react-router-dom';

const PublicGitHubRepoList: React.FC<{
	tableRef?: React.MutableRefObject<
		MaterialTableProps<Record<string, never>> | undefined
	>;
}> = ({tableRef}) => {
	const queryData = useCallback(async (query) => {
		const projectId = await getSharedProjectId();

		const res = callSharedApi
			.get<ProjectAssetsRes>(`/api/v1/project/${projectId}/assets`, {
				params: {
					...paginateParams(query as unknown as DBTableQuery),
					assetType: ProjectAssetType.GitHubRepo,
				},
			})
			.then(async (res) => {
				const dataToRender =
					_.flatMap(res.data.docs, (x) => x.assetIdsWithDetails) ||
					[];

				const assetIds = dataToRender
					.map((x) => x?.assetId)
					.filter(Boolean);

				let scans;

				try {
					scans = await callSharedApi.get(
						`/api/v1/project_scans?assetIds=${assetIds.join(',')}`
					);
				} catch (e) {}

				const dataWithSummary = dataToRender.map((x) => ({
					...x,
					summary: scans?.data.find((z) => z.assetId === x?.assetId)
						?.summary,
				}));

				return {
					data: dataWithSummary,
					page: res.data.page - 1,
					totalCount: res.data.totalDocs,
				};
			});

		return res;
	}, []);

	const onUpdate = () => {
		tableRef?.current?.onQueryChange && tableRef.current?.onQueryChange();
	};

	const {onRefreshRepoList, isRefreshingRepoList} =
		useRefreshPublicRepo(onUpdate);

	const {t} = useTranslation();

	const githubAccountId = useSharedGithubAccountId();

	const repoTableColumns: any = useMemo(
		() => [
			{
				title: t('github.column.repoName'),
				field: 'full_name',
			},
			{
				title: t('malware'),
				field: 'summary.malware',
				type: 'boolean',
				customSort: (a, b) => a.summary?.malware - b.summary?.malware,
				render: (row) =>
					renderMalwareColumnInListPage(row.summary?.malware),
			},
			{
				title: t('vulnerabilities'),
				field: 'vulnerabilityCount',
				type: 'boolean',
				customSort: (a, b) => {
					const aResult = sortBySeverityLevel(
						a.summary?.vulnerabilties
					);
					const bResult = sortBySeverityLevel(
						b.summary?.vulnerabilties
					);
					return bResult - aResult;
				},
				render: (row) =>
					renderCveColumn(row.summary?.vulnerabilties ?? {}),
			},
			{
				title: t('action'),
				render: (rowData) => {
					return (
						<Stack direction="row" spacing={1}>
							<MDButton
								variant="text"
								color="info"
								onClick={() => {
									onRefreshRepoList(
										githubAccountId,
										rowData._id
									);
								}}
								sx={{
									justifyContent: 'flex-start',
									ml: -1,
								}}
								startIcon={
									isRefreshingRepoList ? (
										<CircularProgress size={22} />
									) : null
								}
								disabled={
									isRefreshingRepoList || !githubAccountId
								}
							>
								{t('refresh')}
							</MDButton>

							<MDButton
								component={Link}
								to={`/github/${rowData.full_name}`}
								aria-label="view repo detail"
								color="info"
								variant="text"
							>
								{t('view')}
							</MDButton>
						</Stack>
					);
				},
			},
		],
		[isRefreshingRepoList, githubAccountId]
	);

	return (
		<DBTable
			title={t('github.repoList')}
			tableRef={tableRef}
			isLoading={!githubAccountId}
			data={queryData}
			columns={repoTableColumns}
			detailPanel={[
				(rowData: any) => {
					return {
						render: () => (
							<GithubRepoItemDetail
								rowData={rowData}
								githubAccountId={githubAccountId}
								onUpdate={() => {
									onUpdate();
								}}
							/>
						),
					};
				},
			]}
		/>
	);
};

export const GithubRepoItemDetail = ({rowData, githubAccountId, onUpdate}) => {
	const {t} = useTranslation();

	const dispatch = useDispatch();
	const {fetcher: onScan, loading: isTriggeringScan} = useLoadingIndicator(
		async (projectId: string, assetId: string, sbomStreamId: string) => {
			try {
				await callSharedApi.put(
					`/api/v1/project/${projectId}/${assetId}/${sbomStreamId}/scan`
				);
			} catch (e: any) {
				const meteringControlMsg = e?.response?.data?.data;

				dispatch(
					NotificationStore.actions.show({
						type: 'error',
						msg:
							METERING_CONTROL_MSG[meteringControlMsg] ??
							t('triggerWatchFailed'),
					})
				);

				if (e.isAxiosError && e.response.status == 403) {
					const errorMessageKey = e.response.data.data?.isTeamMember
						? 'error403forTeamMember'
						: 'error403';

					alert(t(errorMessageKey));
				}

				return;
			} finally {
			}

			dispatch(
				NotificationStore.actions.show({
					type: 'success',
					msg: t('projects.scanStarted'),
				})
			);
		}
	);

	const projectId = useSharedProjectId();
	const assetId = rowData.assetId;
	const {data: streamRes, mutate} = useSWR<AxiosResponse<SBOMStreamDef[]>>(
		assetId && projectId
			? `/api/v1/project/${projectId}/assets/${assetId}/streams`
			: null,
		callSharedApi.get
	);

	const {fetcher: onWatchStream, loading: isWatching} = useLoadingIndicator(
		async (
			assetId: string,
			identifier: string,
			action: 'watch' | 'unwatch',
			meta?: object | undefined
		) => {
			try {
				const streamRes = await callSharedApi.put<SBOMStreamDef>(
					`/api/v1/project/${projectId}/${assetId}/stream_watch`,
					{
						action,
						identifier,
						...(meta && {meta}),
					}
				);

				// const stream = streamRes.data;
				//
				// action === 'watch' &&
				// 	(await onScan(projectId, assetId, stream._id));
			} catch (e: any) {
				// TODO: RD-1088
				const meteringControlMsg = e?.response?.data?.data;

				dispatch(
					NotificationStore.actions.show({
						type: 'error',
						msg:
							METERING_CONTROL_MSG[meteringControlMsg] ??
							t('triggerWatchFailed'),
					})
				);

				if (e.isAxiosError && e.response.status == 403) {
					const errorMessageKey = e.response.data.data?.isTeamMember
						? 'error403forTeamMember'
						: 'error403';

					alert(t(errorMessageKey));
				}

				return;
			} finally {
				onUpdate();
				mutate();
			}

			dispatch(
				NotificationStore.actions.show({
					type: 'success',
					msg: 'Tag watched successfully',
				})
			);
		}
	);

	return (
		<MDBox>
			<GitHubRepoAssetSettings
				projectAssetId={assetId}
				isFreeTool
				repoPath={rowData.full_name}
				accountId={githubAccountId as string}
				githubApiUrl={`/api/v1/github/${githubAccountId}/repos/${rowData._id}`}
				onScan={(projectId, assetId, sbomStreamId) =>
					onScan(projectId, assetId, sbomStreamId)
				}
				streams={streamRes?.data || []}
				isWatching={isWatching}
				isTriggeringScan={isTriggeringScan}
				onWatch={(identifier, action) => {
					onWatchStream(rowData.assetId, identifier, action);
				}}
			/>
		</MDBox>
	);
};

const useRefreshPublicRepo = (onUpdate: () => void) => {
	const dispatch = useDispatch();
	const {t} = useTranslation('sbom');
	const onRefreshFn = async (accountId, repoId) => {
		await callSharedApi.put(`/api/v1/github/${accountId}/repos/${repoId}`);

		onUpdate();

		dispatch(
			NotificationStore.actions.show({
				msg: t('gh_refreshRepoSuccess'),
				type: 'success',
			})
		);
	};

	const {fetcher: onRefreshRepoList, loading: isRefreshingRepoList} =
		useLoadingIndicator(onRefreshFn);
	return {onRefreshRepoList, isRefreshingRepoList};
};

export default PublicGitHubRepoList;
